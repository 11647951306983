import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const SingleImage = makeShortcode("SingleImage");
const BasicAudio = makeShortcode("BasicAudio");
const ButtonDialog = makeShortcode("ButtonDialog");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "look-for-the-silver-linings"
    }}>{`Look for the silver linings`}</h2>
    <p>{`As with most challenges in life, the pandemic can present positive opportunities to improve the wellbeing of your teen.`}</p>
    <Typography variant="h4" mdxType="Typography">
  <b>Encourage optimism.</b>
    </Typography>
    <p>{`In a situation like the pandemic, it can be easy to get bogged down in the negatives. As a parent, try to convey a sense of confidence to your teen that things will improve over time. For example, reminding them that scientists are making progress on solutions. Encourage any optimism or hope that your teen shows.`}</p>
    <Typography variant="h4" mdxType="Typography">
  <b>Helping others can help your teen.</b>
    </Typography>
    <p>{`Showing compassion, empathy, and kindness to others can have benefits for your teen. It can help them gain perspective, provide a sense of achievement and pride, and provide opportunities for social interaction.`}</p>
    <p>{`Encourage your teen to take up opportunities to help others when they can. You can set an example for your teen by modelling compassion and empathy yourself `}{`—`}{` for example, reaching out to affected friends or family, or volunteering in the community.`}</p>
    <p>{`Is there anything your teen could do to support others in the community?`}</p>
    <SingleImage smallGridSize={12} gridSize={8} src="/images/m2/silverlinings.svg" alt="Man thinking hard on steps in front of silver lining" mdxType="SingleImage" />
    <Typography variant="h4" color="primary" mdxType="Typography">
  <b>Teens speak.</b>
    </Typography>
    <p><em parentName="p">{`Have there been any good things about the pandemic for you? Hear what Kai, 15, has to say.`}</em></p>
    <BasicAudio src="/audios/Teens_speak_positives_pandemic.mp3" mdxType="BasicAudio" />
    <ButtonDialog linkText="Read Transcript" title={<i>Transcript:</i>} mdxType="ButtonDialog">
  <b> Have there been any good things about the pandemic for you? Kai, 15, says:</b>
  <br />
  <br />
  Yeah, to be honest, being stuck at home during lockdowns isn’t all bad. I mean, yeah, some of it does suck. But the sleep-ins?
  Love it! I have way more time to sleep in since we don’t have to do a mad rush to school in the morning, and I don’t have
  to put up with my Mum freaking out about being late all the time! I literally just roll out of bed and turn on my laptop.
  Usually, with normal school, I never get enough sleep and I’m always tired during the week. So lately I actually feel way
  better during the day, like I’ve actually got enough sleep. And also school really take the pressure off us, we have less
  tests and things which make it less stressful.
  <br />
  <br />
  It’s also cool just having more free time to myself and getting to relax. There’s less pressure to be doing things all
  the time, I can just chill out more.
  <br />
  <br />
  Plus, getting to spend more time with the family and do nice things together is good (not that I’d tell my parents I like
  spending time with them, of course!). I mean yeah, we do get on each other’s nerves sometimes, but I think during lockdown
  we spend more quality time together than we have in a long time. Like, just going on walks, playing games, cooking together,
  watching movies and things. My parents seem to be putting in effort to support me and make sure that me and my brother
  are okay, which I do appreciate. I actually hope some of this continues even after the lockdowns…but not too much because
  I still need my space sometimes!
    </ButtonDialog>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      